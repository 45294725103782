import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import NewsletterBanner from "../../components/newsletter-banner";
import { BsCircleFill } from "react-icons/bs";

const AirPollutionPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Air-Pollution-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Air Pollution",
				item: {
					url: `${siteUrl}/about-us/air-pollution`,
					id: `${siteUrl}/about-us/air-pollution`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Air Pollution | The Ella Roberta Foundation"
				description="Invisible, with no smell or taste, air pollution is a difficult enemy to know. Here we help you understand what it is, why it's so bad for you, and what you can do to reduce your exposure."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/air-pollution`,
					title: "Air Pollution | The Ella Roberta Foundation",
					description:
						"Invisible, with no smell or taste, air pollution is a difficult enemy to know. Here we help you understand what it is, why it's so bad for you, and what you can do to reduce your exposure.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bg-white">
					<Hero
						bgPos="center"
						title={
							<span>
								ABOUT AIR
								<br />
								POLLUTION
							</span>
						}
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
					<section className="pt-5 pb-lg-5">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5 pb-ld-0 text-center">
									<h2 className="fs-1 text-primary pb-4">
										FREQUENTLY ASKED QUESTIONS
									</h2>
									<p>
										Invisible, with no smell or taste, air pollution is a
										difficult enemy to know. Here we help you understand what it
										is, why it's so bad for you, and what you can do to reduce
										your exposure.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5  ">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5  ">
									<h3 className="  pb-4">WHAT ARE THE MAIN POLLUTANTS?</h3>
									<p>
										When we talk about air pollution in cities we are mainly
										talking about:
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Nitrogen Dioxide (N02): This is a type of gas, often
										associated with vehicles, especially if they run on diesel
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Particulate Matter (PM2.5): These are tiny particles
										suspended in the air. This is often caused by the wear and
										tear of car engines and braking, as well as dust blown in on
										the wind.
									</p>
									<p>
										To find out more about the key air pollutants go to{" "}
										<a
											href="https://www.blf.org.uk/support-for-you/air-pollution/types"
											target="_blank"
											rel="noreferrer"
										>
											Asthma + Lung UK
										</a>
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-background ">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5 ">
									<h3 className="  pb-4">
										WHERE DOES AIR POLLUTION COME FROM?
									</h3>
									<p>
										In cities (
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.gov.uk%2Fgovernment%2Fpublications%2Ftrend-deck-2021-urbanisation%2Ftrend-deck-2021-urbanisation%23%3A~%3Atext%3DIn%25202019%252C%252056.3%2520million%2520people%2Cin%2520rural%2520areas%2520(17.1%2525).&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203711381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=VLCJyHtcGy5SpkhpLLh5bL%2Ff9G9%2F0oIYSH8%2BoQLgYK0%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											where 83% of people in the UK live
										</a>
										) one of the main sources of air pollution is road traffic;
										cars, vans, buses and lorries. The World Health Organisation
										(WHO) says car exhaust emissions from road transport account
										for up to{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.euro.who.int%2Fen%2Fdata-and-evidence%2Fevidence-informed-policy-making%2Fpublications%2Fhen-summaries-of-network-members-reports%2Fwhat-are-the-effects-on-health-of-transport-related-air-pollution&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203711381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=vw%2B47n2FTasMt4hbp%2BEWJs4phscRmBSZ6EZ4C5KMYMk%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											30% of fine PM
										</a>{" "}
										in urban areas, and{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.gov.uk%2Fgovernment%2Fstatistics%2Femissions-of-air-pollutants%2Femissions-of-air-pollutants-in-the-uk-nitrogen-oxides-nox%23%3A~%3Atext%3DRoad%2520transport%2520accounted%2520for%252028%2Caccounted%2520for%252013%2520per%2520cent.&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203711381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=2KIVEHx7F4HRORQD51EPs67CBFlqjL32iR3CBCehGYw%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											28% of nitrogen dioxide
										</a>
										.
									</p>
									<p>
										Another big contributor is wood burning; the smoke from
										barbecues, firepits and wood burners.{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.gov.uk%2Fgovernment%2Fstatistics%2Femissions-of-air-pollutants%2Femissions-of-air-pollutants-in-the-uk-particulate-matter-pm10-and-pm25&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203711381%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=AhDSdkFhU%2Fks9oXC5P%2BDCtfufrc%2BLA7B99NpZBY4eGw%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											Government figures
										</a>{" "}
										show that more than a third of all PM2.5 in the UK in 2019
										came from domestic sources, such as wood burning stoves.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 bg-background ">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5 ">
									<h3 className="  pb-4">
										WORLD HEALTH ORGANISATION (WHO) GUIDELINES
									</h3>
									<p>
										The World Health Organisation (WHO) most recent guidelines
										were issued in September 2021, updating the previous
										guidelines that were issued in 2005. The targets were
										brought down, based on scientific and medical evidence that
										shows that millions of lives could be saved with lower
										levels of air pollution.
									</p>
									<p>
										For more about the air pollution targets, visit{" "}
										<a
											href="https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health"
											target="_blank"
											rel="noreferrer"
										>
											WHO
										</a>
										.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 bg-background ">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5 ">
									<h3 className="  pb-4">
										WHY ARE CHILDREN PARTICULARLY AFFECTED BY AIR POLLUTION?
									</h3>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Children are shorter than adults, and closer to the ground.
										This means they are also closer to car exhausts, the source
										of many pollutants.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.unicef.org%2Frosa%2Fstories%2Ftoxic-air-harming-our-children-every-breath-they-take%23%3A~%3Atext%3DA%2520typical%2520adult%2520takes%2520between%2Cas%2520much%2520as%2520adults%2520are.&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=S%2FisKXxE2yS3L7doHIsjAGjqGjKRPoldjdw4iItuu04%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											A child breathes in more air than an adult
										</a>
										. A typical adult takes between 12 and 18 breaths each
										minute, a young child takes between 20-30, and a newborn
										takes as many as 30-40. This means children are taking in
										between 2-3 times as much polluted air as adults.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Children’s organs are still developing, and{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.thelancet.com%2Fjournals%2Flanpub%2Farticle%2FPIIS2468-2667(18)30202-0%2Ffulltext&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=ylcazMvjDdKy7%2FqGdE0XfUpEA8yUekN4wlZ7lH2hvv4%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											air pollution reduces their size
										</a>
										. This is key as lung capacity peaks at 18, and then
										declines throughout the rest of a person’s life. If a person
										has lungs smaller than their body needs, they are at a
										higher risk of early death, as well as lung diseases.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Journeys children make every day, like going to school,{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.kcl.ac.uk%2Fnews%2Fchildren-exposed-to-five-times-more-air-pollution-on-school-run%23%3A~%3Atext%3DA%2520new%2520King%27s%2520report%2520has%2Cother%2520time%2520of%2520the%2520day.&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=gjHdfjmRei9NTSlqvwmAjXHPb1NY3UiBcmD6OLds2jY%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											are harmful to their lung health
										</a>
										, as they are exposed to up to five times the amount of
										pollution compared to any other time. This is because the
										school run, particularly in the morning, is when most
										traffic is on the road. These high levels of pollution are
										hard to avoid, as much of the traffic is going to school, so
										is on the roads surrounding the school.
									</p>

									<p>
										To find out more about air pollution and children go to{" "}
										<a
											href="https://www.mumsforlungs.org/"
											rel="noreferrer"
											target="_blank"
										>
											Mums For Lungs
										</a>
										.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 bg-background ">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5 ">
									<h3 className="  pb-4">
										HOW DOES AIR POLLUTION AFFECT CHILDREN WITH ASTHMA?
									</h3>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Around two thirds of people who have asthma say air
										pollution makes it worse as it irritates airways and
										triggers asthma symptoms. In addition to this,{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.bmj.com%2Fcontent%2F370%2Fbmj.m2791&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=O74Zl6SKpFGV9ylCYm%2FxBYflTHBgZ6zUh8EqQHLwbwk%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											medical research
										</a>{" "}
										shows that in some cases air pollution causes asthma.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										According to{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.thelancet.com%2Fjournals%2Flanplh%2Farticle%2FPIIS2542-5196(21)00255-2%2Ffulltext&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=OE2s9b9ghTTi3ks%2BOi8AxL%2Bpp8k0nrIgdtEUGxhnwEc%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											recent research
										</a>
										, one in 12 new child asthma cases is related to nitrogen
										dioxide released by diesel vehicles.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.theguardian.com%2Fenvironment%2F2021%2Fmay%2F18%2Fair-pollution-linked-to-huge-rise-in-child-asthma-gp-visits%23%3A~%3Atext%3DA%2520%25E2%2580%259Chuge%25E2%2580%259D%2520increase%2520in%2520the%2Cpollution%252C%2520according%2520to%2520a%2520study.&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=gGY0foxoUssY4lf07ZaEF9kDyYE3UUaZd5%2BOtLdbz5o%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											Clinical data
										</a>{" "}
										shows that a week after an air pollution spike, there is a
										huge increase in the number of people who go to their GP,
										with children the most affected. Week 38, in September, is
										when admissions to hospital with asthma is at its highest.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										One in 11 children and young people have asthma in the UK.{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.england.nhs.uk%2Fchildhood-asthma%2F&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=CUS%2FmbiLRaxB7%2B1xKw8cqTL40CKqO1Zth5iQWfnAOIM%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											We have the highest level of occurrence
										</a>
										, emergency admissions and deaths for childhood asthma in
										Europe.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.london.gov.uk%2Fpress-releases%2Fmayoral%2Fpoor-air-quality-aggrevates-london-asthmatics%23%3A~%3Atext%3DIt%2520is%2520estimated%2520600%252C000%2520people%2Cfrom%2520asthma%252C%2520including%2520240%252C000%2520children.&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=L8J%2FjM6ZiC1Q50U9pBcTZ4RtPwvwypuuA7FJzDCZs7M%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											Quarter of a million children in London have asthma
										</a>
										,{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.healthylondon.org%2Fwp-content%2Fuploads%2F2017%2F10%2FHLP-Asthma-case-for-change-summary.pdf&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=DGzzKzEAWFsORmXaaPjg04e34CfAL9ToKjCfYDOdFAs%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											three in every classroom
										</a>
										.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Between eight and twelve children a year die of asthma in
										London.{" "}
										<a
											href="https://www.asthmaandlung.org.uk/"
											target="_blank"
											rel="noreferrer"
										>
											Asthma + Lung UK
										</a>
										.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5  py-lg-8 bg-primary">
						<Container>
							<Row>
								<Col className="text-white">
									<h2 className="display-3 pb-5 text-white text-center pb-5 heading-text">
										DID YOU KNOW?
									</h2>
									<p>
										<span style={{ fontSize: "80%" }} className="me-4">
											<BsCircleFill />
										</span>
										Asthma is a lung condition that makes it difficult to
										breathe.
									</p>
									<p>
										<span style={{ fontSize: "80%" }} className="me-4">
											<BsCircleFill />
										</span>
										Asthma is rarely diagnosed in children under five as it is
										difficult for them to do the necessary tests, and it could
										be that the main symptoms of coughing and breathlessness are
										caused by other conditions.
									</p>
									<p>
										<span style={{ fontSize: "80%" }} className="me-4">
											<BsCircleFill />
										</span>
										Asthma can’t be cured, but medicine can reduce the symptoms.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-8 ">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5 ">
									<h3 className="  pb-4">DO WE ALL BREATHE THE SAME AIR?</h3>
									<p>
										Air pollution is often worse in areas that are more
										ethnically diverse.{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.imperial.ac.uk%2Fnews%2F163408%2Fethnic-minorities-deprived-communities-hardest-pollution%2F%23%3A~%3Atext%3DThe%2520worst%2520air%2520pollution%2520levels%2Cm3%2520for%2520NO2.&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=EbWjUOO0VKOy5ijUh95ksk0BIP74Wd%2BfU%2BfQJp2a60g%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											Research in London
										</a>{" "}
										shows that, even allowing for deprivation, areas where more
										than 20% of the population are non-white had the worst air
										pollution levels.
									</p>
									<p>
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.london.gov.uk%2Fsites%2Fdefault%2Ffiles%2Fair_pollution_and_inequalities_in_london_2019_update_0.pdf&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=FLYVSw0JwRx3nm0sRNYD%2FJLKQiZ%2FIlCwUI8g25Y8vAY%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											Other research
										</a>{" "}
										shows that between 31 and 35% of areas with the highest
										proportion of black and mixed/multiple ethnicities are in
										areas with higher levels of air pollution, reducing to 15-18
										% for Asian ethnic groups and just 4-5 % for white ethnic
										groups.
									</p>

									<p>
										Follow{" "}
										<a
											href="https://www.google.com/search?q=ChokedUp&oq=ChokedUp&aqs=chrome..69i57j0i10i512j0i10l8.288j0j7&sourceid=chrome&ie=UTF-8"
											target="_blank"
											rel="noreferrer"
										>
											ChokedUp
										</a>{" "}
										for more on diversity and air pollution.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5  ">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5 ">
									<h3 className="  pb-4">
										HOW CAN I REDUCE MY EXPOSURE TO AIR POLLUTION?
									</h3>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										If you want to reduce your personal exposure to air
										pollution there are a few things you can do:
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fuk-air.defra.gov.uk%2Fforecasting%2F&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=uYpBZoIQHOTRov59hBCUaKXyF1IeW383h3%2FWktiyQeM%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											Check the government’s air pollution forecast
										</a>
										. If air pollution is forecast high, change your routine to
										reduce your exposure.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Avoid pollution hotspots like main roads and take back
										routes away from busy streets when you can. Don’t drive -
										air pollution is{" "}
										<a
											href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fjournals.sagepub.com%2Fdoi%2Fabs%2F10.1177%2F1420326X16679217&data=05%7C01%7CS.Holgate%40soton.ac.uk%7C09426bc9c2fd42fbcfec08da43abf515%7C4a5378f929f44d3ebe89669d03ada9d8%7C0%7C0%7C637896703203867626%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=n7%2FMCZUFLXCe9713JbqIjyU6B3cP9aK%2B3%2BjyM1kMr2w%3D&reserved=0"
											target="_blank"
											rel="noreferrer"
										>
											higher inside
										</a>{" "}
										a car than outside.
									</p>
									<p>
										<span
											style={{ fontSize: "80%" }}
											className="text-primary me-4"
										>
											<BsCircleFill />
										</span>
										Walk short journeys, to reduce the amount of air pollution
										you produce.
									</p>

									<p>
										For more information on reducing your exposure check the{" "}
										<a
											href="https://www.asthmaandlung.org.uk/"
											target="_blank"
											rel="noreferrer"
										>
											Asthma + Lung UK Website
										</a>
										.
									</p>
								</Col>
							</Row>
						</Container>
					</section>

					<NewsletterBanner />
				</div>
			</Layout>
		</>
	);
};

export default AirPollutionPage;
